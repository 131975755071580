import { MutableRefObject, ReactElement, RefObject, useCallback, useEffect, useRef } from "react";
import { ICohortRewards as ICohortReward, IItem } from "../../messages/IGetUserStatisticsResponse";

export default function CohortStatistics(props: {
    item: IItem,
    cohortReward: ICohortReward;
}): ReactElement {
    const canvas0Ref = useRef<HTMLCanvasElement>(null);
    const chart0Ref = useRef<Chart | null>();

    const canvas1Ref = useRef<HTMLCanvasElement>(null);
    const chart1Ref = useRef<Chart | null>();

    const canvas2Ref = useRef<HTMLCanvasElement>(null);
    const chart2Ref = useRef<Chart | null>();

    const canvas3Ref = useRef<HTMLCanvasElement>(null);
    const chart3Ref = useRef<Chart | null>();

    const destroyChart = useCallback((chartRef: MutableRefObject<Chart | null | undefined>) => {
        chartRef.current?.destroy();
        chartRef.current = null;
    }, []);

    const destroyCharts = useCallback(() => {
        destroyChart(chart0Ref);
        destroyChart(chart1Ref);
        destroyChart(chart2Ref);
        destroyChart(chart3Ref);
    }, [destroyChart, chart0Ref, chart1Ref, chart2Ref, chart3Ref]);

    const renderChart = useCallback((chartRef: MutableRefObject<Chart | null | undefined>, canvasRef: RefObject<HTMLCanvasElement>) => {
        if (!canvasRef.current) return;

        destroyChart(chartRef);
        chartRef.current = createDoughnutChartForRewards(canvasRef.current);
    }, [destroyChart]);

    const renderCharts = useCallback(() => {
        renderChart(chart0Ref, canvas0Ref);
        renderChart(chart1Ref, canvas1Ref);
        renderChart(chart2Ref, canvas2Ref);
        renderChart(chart3Ref, canvas3Ref);
    }, [
        renderChart,
        chart0Ref, chart1Ref, chart2Ref, chart3Ref,
        canvas0Ref, canvas1Ref, canvas2Ref, canvas3Ref
    ]);


    useEffect(() => {
        renderCharts();

        return () => destroyCharts();
    });

    const unit = props.item.unit || 0;
    const last_unit = props.item.last_unit || 0;
    const next_unit_change = props.item.next_unit_change || null;
    const next_unit_change_text = props.item.next_unit_change_text || null;
    const labs_done = props.item.labs_done || 0;
    const livepoints_sum = props.item.livepoints_sum || 0;
    const labs_count = props.item.lab_list_length || 0;

    const lessons_done = props.item.lessons_done || 0;
    const lessons_count = props.item.lessons_count || 0;

    const penalty_bonus_max = props.item.penalty_bonus_max || 0;
    const penalty_bonus_sum = props.item.penalty_bonus_sum || 0;
    const penalty_bonus_aged_sum = props.item.penalty_bonus_aged_sum || 0;
    const partime_bonus_max = props.item.partime_bonus_max || 0;
    const partime_bonus_sum = props.item.partime_bonus_sum || 0;
    const partime_bonus_aged_sum = props.item.partime_bonus_aged_sum || 0;

    const bonus_sum = props.item.bonus_sum || 0;
    const bonus_aged_sum = props.item.bonus_aged_sum || 0;
    const points_sum = props.item.points_sum || 0;
    const points_aged_sum = props.item.points_aged_sum || 0;

    const cohort_lessons_done_avg = Math.round((props.cohortReward.lessons_done_avg || 0) * 10) /10;
    const cohort_lessons_percentage = lessons_count > 0 ? Math.round(100 * cohort_lessons_done_avg / lessons_count) : 0;

    const cohort_labs_done_avg = Math.round((props.cohortReward.labs_done_avg || 0) * 10) / 10;
    const cohort_livepoints_avg = Math.round((props.cohortReward.livepoints_avg || 0) * 10) / 10;

    const labs_percentage = labs_count > 0 ? 100 * labs_done / labs_count : 0;
    const lessons_percentage = lessons_count > 0 ? Math.round(100 * lessons_done / lessons_count) : 0;

    const cohort_penalty_bonus_avg = Math.round((props.cohortReward.penalty_bonus_avg || 0) * 10) / 10;
    const cohort_penalty_bonus_aged_avg = Math.round((props.cohortReward.penalty_bonus_aged_avg || 0) * 10) / 10;
    const cohort_partime_bonus_avg = Math.round((props.cohortReward.partime_bonus_avg || 0) * 10) / 10;
    const cohort_partime_bonus_aged_avg = Math.round((props.cohortReward.partime_bonus_aged_avg || 0) * 10) / 10;

    const cohort_bonus_avg = Math.round((props.cohortReward.bonus_avg || 0) * 10) / 10;
    const cohort_bonus_aged_avg = Math.round((props.cohortReward.bonus_aged_avg || 0) * 10) / 10;
    const cohort_points_avg = Math.round((props.cohortReward.points_avg || 0) * 10) / 10;
    const cohort_points_aged_avg = Math.round((props.cohortReward.points_aged_avg || 0) * 10) / 10;
    const cohort_max_points_avg = props.cohortReward.max_points_avg || 0;

    const hintsUsedBonusHoverMessage = `You had ${penalty_bonus_sum} bonus points for hints used (out of ${penalty_bonus_max}).\nThe cohort had ${cohort_penalty_bonus_avg} in average.\nYour bonus has been diminished to ${penalty_bonus_aged_sum} due to aging.`;

    let labsFinishedHoverMessage = `You finished ${labs_done} labs out of ${labs_count}`;
    if (labs_percentage > 0) {
        labsFinishedHoverMessage += ` (${labs_percentage}%)`;
    }
    labsFinishedHoverMessage += `\n(the cohort did ${cohort_labs_done_avg} in average)`;

    const livePointsHoverMessage = `You had ${points_sum} points + ${bonus_sum} bonus\n(the cohort had ${cohort_points_avg} points + ${cohort_bonus_avg} bonus in average)\nYour points has been diminished to ${livepoints_sum} due to aging`;

    const partimeBonusHoverMessage = `You had ${partime_bonus_sum} bonus points for par time (out of ${partime_bonus_max}).\nThe cohort had ${cohort_partime_bonus_avg} in average.\nYour bonus has been diminished to ${partime_bonus_aged_sum} due to aging.`;

    const progressionHoverMessage = `You listened through ${lessons_done} lessons out of ${lessons_count}${lessons_percentage>0 ? " ("+lessons_percentage+"%)" : ""}.\n(the cohort finished ${cohort_lessons_done_avg} in average)`;

    return <>
        <div className="chart small">
            <p title="The bonus points you gained by NOT using extra hints vs&#013;the average bonus points of the cohort.">
                Hints used bonus
            </p>

            <div className="pie">
                <canvas ref={canvas0Ref} className="chart_in" id="hints__inner"
                    data-p1={`${penalty_bonus_aged_sum}`}
                    data-p1-aged-diff={`${penalty_bonus_sum - penalty_bonus_aged_sum}`}
                    data-range1={`${penalty_bonus_max}`}
                    data-p2={`${cohort_penalty_bonus_aged_avg}`}
                    data-p2-aged-diff={`${cohort_penalty_bonus_avg - cohort_penalty_bonus_aged_avg}`}
                    data-range2={`${penalty_bonus_max}`}
                    title={hintsUsedBonusHoverMessage}
                ></canvas>
                <div className="count" title={hintsUsedBonusHoverMessage}>
                    <span className="done">{penalty_bonus_aged_sum} points</span>
                </div>
            </div>
        </div>

        <div className="chart counter">
            <p title="How many labs did you finish (green) out of all available vs&#013;the average number of labs finished by other members of your cohort (blue).">
                Labs done
            </p>

            <div className="pie">
                <canvas ref={canvas1Ref} className="chart_in" id="lab__inner"
                    data-p1={`${labs_done}`}
                    data-range1={`${labs_count}`}
                    data-p2={`${cohort_labs_done_avg}`}
                    data-range2={`${labs_count}`}
                    title={labsFinishedHoverMessage}
                ></canvas>
                <div className="count" title={labsFinishedHoverMessage}>
                    <span className="done">{labs_done}</span>
                    /
                    <span className="all">{labs_count}</span>
                </div>
            </div>
        </div>

        <div className="chart">
            <p title="Your live points vs the average live points of all members of your cohort.&#013;The bonus is indicated with dark color, and the points that have been already diminished by aging with light color.">
                Live points
            </p>

            <div className="pie">
                <canvas ref={canvas2Ref} className="chart_in" id="points__inner"
                    data-p1={`${points_aged_sum}`}
                    data-p1-bonus={`${bonus_aged_sum}`}
                    data-p1-aged-diff={`${points_sum + bonus_sum - livepoints_sum}`}
                    data-range1={`${cohort_max_points_avg}`}
                    data-p2={`${cohort_points_aged_avg}`}
                    data-p2-bonus={`${cohort_bonus_aged_avg}`}
                    data-p2-aged-diff={`${cohort_points_avg + cohort_bonus_avg - cohort_livepoints_avg}`}
                    data-range2={`${cohort_max_points_avg}`}
                    title={livePointsHoverMessage}
                ></canvas>
                <div className="count" title={livePointsHoverMessage}>
                    <span className="done">{livepoints_sum} LP</span>
                </div>
            </div>
        </div>

        <div className="chart small">
            <p title="The bonus points you gained by doing the lab more quickly than the expected par time vs&#013;the average bonus points of the cohort.">
                Par time bonus
            </p>

            <div className="pie">
                <canvas ref={canvas3Ref} className="chart_in" id="time__inner"
                    data-p1={`${partime_bonus_aged_sum}`}
                    data-p1-aged-diff={`${partime_bonus_sum - partime_bonus_aged_sum}`}
                    data-range1={`${partime_bonus_max}`}
                    data-p2={`${cohort_partime_bonus_aged_avg}`}
                    data-p2-aged-diff={`${cohort_partime_bonus_avg - cohort_partime_bonus_aged_avg}`}
                    data-range2={`${partime_bonus_max}`}
                    title={partimeBonusHoverMessage}
                ></canvas>
                <div className="count" title={partimeBonusHoverMessage}>
                    <span className="done">{partime_bonus_aged_sum} points</span>
                </div>
            </div>
        </div>

        { lessons_count>0 && <div className="lesson_progress">
            <div className="progressbar_title reward_progressbar" title={progressionHoverMessage}>
                Progression:&nbsp;
                <span className="done">{`${lessons_done}`}</span>
                /
                <span className="all">{`${lessons_count}`}</span>
                &nbsp;lessons done
                { unit>0 ?
                    <>
                    <span className="unit"> (you are in Unit {`${unit}${next_unit_change ? ", next unit will be shown on "+next_unit_change_text 
                                                                                         : (unit===last_unit ? ", this is your last learning unit" : "")}`})</span>
                    </>
                :   <> { unit===0 && next_unit_change_text &&
                        <span className="unit"> (your first learning unit will be shown on {`${next_unit_change_text}`})</span>
                    }
                    </>
                }
            </div>
            <div className="reward_progressbar progression_progressbar" title={progressionHoverMessage}>
                <div id="group_slides_done" className="progress_indicator cohort_progress"
                    style={{width: `${cohort_lessons_percentage}%`}}
                ></div>
            </div>
            <hr className="separator"/>
            <div className="reward_progressbar progression_progressbar" title={progressionHoverMessage}>
                <div id="my_slides_done" className="progress_indicator my_progress"
                    style={{width: `${lessons_percentage}%`}}
                ></div>
            </div>
        </div>
        }
    </>;
}
